import * as netlifyIdentity from "netlify-identity-widget";

//@ts-ignore
window.__SF = {
  login: () => netlifyIdentity.open("login"),
  signup: () => netlifyIdentity.open("signup"),
};

window.addEventListener("load", () => {
  const init = () => {
    const appDiv = window.document.createElement("div");
    appDiv.id = "app";
    const body = window.document.body;
    body.appendChild(appDiv);
    import("./App").then(({ startApp }) => {
      startApp();
    });
  };
  init();

  netlifyIdentity.on("init", (user) => {
    if (user != null && Number(user.token.expires_at) < new Date().getTime()) {
      netlifyIdentity.refresh().then((token) => window.location.reload());
    }
  });

  netlifyIdentity.on("login", (user) => {
    netlifyIdentity.close();
    if (window.location.pathname === "/" && user != null) {
      window.location.replace("/home");
    }
  });

  netlifyIdentity.init({
    logo: false,
  });
});
